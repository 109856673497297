import { PlayerActions } from "./GameBlock"
import config from "../../config"
import { SMALL_SCREEN } from "../../utils"
import { useViewport } from "../../hooks/useViewport"
import { useState } from "react"
import { useSwipeable } from "react-swipeable"

type TableProps = {
  players: PlayerActions[]
  cn: string
  expanded: boolean
}

type DetailProps = {
  homePlayers: PlayerActions[]
  homeGoalies: PlayerActions[]
  awayPlayers: PlayerActions[]
  awayGoalies: PlayerActions[]
  noDetails: boolean
  expanded: boolean
  homeLogoUrl: string
  awayLogoUrl: string
}

const PlayerRow = ({ p, expanded }: { p: PlayerActions; expanded: boolean }) => {
  return (
    <tr>
      <td>
        <img
          alt="natflag"
          src={`${config.staticBaseUrl}/country_flags/${p.player_nationality}.svg`}
          width="18px"
        />
      </td>
      <td>{p.player_name}</td>
      <td>{p.goals}</td>
      <td>{p.assists}</td>
      {expanded && (
        <>
          <td>{p.time_on_ice}</td>
          <td>{p.plus_minus}</td>
          <td>{p.shots}</td>
          <td>{p.hits}</td>
          <td>{p.blocks}</td>
          <td>{p.penalty_minutes}</td>
        </>
      )}
    </tr>
  )
}

const GoalieRow = ({ p, expanded }: { p: PlayerActions; expanded: boolean }) => {
  return (
    <tr>
      <td>
        <img
          alt="natflag"
          src={`${config.staticBaseUrl}/country_flags/${p.player_nationality}.svg`}
          width="15px"
        />
      </td>
      <td>{p.player_name}</td>
      <td>{p.goals_allowed}</td>
      <td>{p.save_percentage}</td>
      {expanded && (
        <>
          <td>{p.saves}</td>
          <td>{p.shots_against}</td>
          <td>{p.shutout}</td>
        </>
      )}
    </tr>
  )
}

const PlayerTable = ({ players, cn, expanded }: TableProps) => {
  return (
    <table className={`detailsTable ${cn}`}>
      <thead>
        <tr>
          <th>Nat</th>
          <th>Name</th>
          <th>Goals</th>
          <th>Assists</th>
          {expanded && (
            <>
              <th>TOI</th>
              <th>+-</th>
              <th>Shots</th>
              <th>Hits</th>
              <th>Blocks</th>
              <th>PIM</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {players.map((p: PlayerActions, i) => {
          return <PlayerRow key={i} {...{ p, expanded }} />
        })}
      </tbody>
    </table>
  )
}

const GoalieTable = ({ players, cn, expanded }: TableProps) => {
  const goalies = players

  return (
    <table className={`detailsTable ${cn}`}>
      <thead>
        <tr>
          <th>Nat</th>
          <th>Name</th>
          <th>Goals allowed</th>
          <th>Save percentage</th>
          {expanded && (
            <>
              <th>Saves</th>
              <th>Shots against</th>
              <th>Shutout</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {goalies.map((p: PlayerActions, i) => {
          return <GoalieRow key={i} {...{ p, expanded }} />
        })}
      </tbody>
    </table>
  )
}

export const DetailsBlock: React.FC<DetailProps> = ({
  homePlayers,
  homeGoalies,
  awayPlayers,
  awayGoalies,
  noDetails,
  expanded,
  homeLogoUrl,
  awayLogoUrl
}) => {
  const { width } = useViewport()
  const isSmallScreen = width <= SMALL_SCREEN

  if (noDetails) {
    return <></>
  }

  const DefaultDetails = () => {
    return <div className="detailBlock">
        {homePlayers.length > 0 ? (
          <PlayerTable {...{ players: homePlayers as PlayerActions[], cn: "home", expanded }} />
        ) : (
          <></>
        )}
        {awayPlayers.length > 0 ? (
          <PlayerTable {...{ players: awayPlayers as PlayerActions[], cn: "away", expanded }} />
        ) : (
          <></>
        )}
        <div className="line" />
        {homeGoalies.length > 0 ? (
          <GoalieTable {...{ players: homeGoalies as PlayerActions[], cn: "homeGoalie", expanded }} />
        ) : (
          <></>
        )}
        {awayGoalies.length > 0 ? (
          <GoalieTable {...{ players: awayGoalies as PlayerActions[], cn: "awayGoalie", expanded }} />
        ) : (
          <></>
        )}
    </div>
  }

  const MobileDetails = () => {
    const [mobileHomeView, setMobileHomeView] = useState(true)

    const rightHandlers = useSwipeable({
      onSwipedRight: () => setMobileHomeView(true)
    });
    const leftHandlers = useSwipeable({
      onSwipedLeft: () => setMobileHomeView(false)
    });

    return <div className="mobileDetailBlock">
        {
          mobileHomeView && 
          <div {...leftHandlers}>
            <img alt="awaylogo" src={homeLogoUrl} width="50px" height="50px" />
            {homePlayers.length > 0 ? (
              <PlayerTable {...{ players: homePlayers as PlayerActions[], cn: "home", expanded }} />
            ) : (
              <></>
            )}
            {homeGoalies.length > 0 ? (
              <GoalieTable {...{ players: homeGoalies as PlayerActions[], cn: "homeGoalie", expanded }} />
            ) : (
              <></>
            )}
          </div>
        }
        {
          !mobileHomeView && 
          <div {...rightHandlers}>
            <img alt="awaylogo" src={awayLogoUrl} width="50px" height="50px" />
            {awayPlayers.length > 0 ? (
              <PlayerTable {...{ players: awayPlayers as PlayerActions[], cn: "away", expanded }} />
            ) : (
              <></>
            )}
            {awayGoalies.length > 0 ? (
              <GoalieTable {...{ players: awayGoalies as PlayerActions[], cn: "awayGoalie", expanded }} />
            ) : (
              <></>
            )}
        </div>
        }
    </div>
  }

  return (
    <>
      {!isSmallScreen && <DefaultDetails/>}
      {isSmallScreen && <MobileDetails/>}
    </>
  )
}
