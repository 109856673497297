import { useState } from "react"
import { BothKeys } from "./Stats"
import { Columns } from "./StatsTable"

type StatsTableHeadProps = {
  columns: Columns
  type: "P" | "G"
  handleSorting: (accessor: BothKeys, sortOrder: string, type: "P" | "G") => void
}

const TableHead: React.FunctionComponent<StatsTableHeadProps> = ({
  columns,
  type,
  handleSorting,
}) => {
  const [sortField, setSortField] = useState("")
  const [order, setOrder] = useState("desc")

  const handleSortingChange = (accessor: BothKeys) => {
    const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc"
    setSortField(accessor)
    setOrder(sortOrder)
    handleSorting(accessor, sortOrder, type)
  }

  return (
    <thead>
      <tr>
       {columns.flatMap(({ label, accessor, sortable }) => {
            if (accessor === "season_total_power_play_assists" || 
                accessor === "season_total_power_play_goals" ||
                accessor === "season_total_short_handed_assists" ||
                accessor === "season_total_short_handed_goals") {
                return [];
            }
            if (accessor === "season_total_power_play_points" || 
                accessor === "season_total_short_handed_points") {
                label = `${label} (g/a)`
            }
            if (accessor === "player_team_id" || accessor === "player_nationality") {
                return <th key={accessor} id='table-img'>{label}</th>;
            }
            const cl = sortField === accessor && order === "asc"
            ? "up"
            : sortField === accessor && order === "desc"
            ? "down"
            : "default";
            return <th key={accessor} onClick={sortable ? () => handleSortingChange(accessor as BothKeys) : undefined} className={cl}>{label}</th>;
       })}
      </tr>
    </thead>
  )
}

export default TableHead
