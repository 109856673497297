import { GameBlock, GameInterface } from "./GameBlock/GameBlock"

type GameListProps = {
  loaded: boolean
  games: GameInterface[]
  nationality: string
  expanded: boolean
}

export const GameList: React.FunctionComponent<GameListProps> = ({
  loaded,
  games,
  nationality,
  expanded,
}) => {
  const sortByNationality = (a: GameInterface, b: GameInterface) => {
    const nat = nationality !== "ALL" ? nationality : null
    if (nat) {
      const playerFoundFromTeamA = a.players.some((p) => {
        return p.player_nationality === nat
      })
      const playerFoundFromTeamB = b.players.some((p) => {
        return p.player_nationality === nat
      })

      if (!playerFoundFromTeamA && playerFoundFromTeamB) {
        return 1
      }
      if (playerFoundFromTeamA && !playerFoundFromTeamB) {
        return -1
      }
      return 0
    }
    return 0
  }

  const orderedGames = games.sort(sortByNationality)

  return (
    <div>
      {loaded ? (
        <div>
          {games.length === 0 ? (
            <div>No games today bro</div>
          ) : (
            orderedGames.map((game, i: number) => {
              return (
                <div key={i} className="GameRow">
                  <GameBlock key={i} game={game} nationality={nationality} expanded={expanded} />
                </div>
              )
            })
          )}
        </div>
      ) : (
        <div>Loading</div>
      )}
    </div>
  )
}
