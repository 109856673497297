import DatePicker from "react-datepicker"
import React from "react"
import "react-datepicker/dist/react-datepicker.css"
import "./DatePicker.css"

type DatePickerProps = {
  // yesterday: () => Date
  // tomorrow: () => Date
  handleDateChange: (date: Date) => void
  selectedDate: Date
}

export const CustomDatePicker: React.FC<DatePickerProps> = ({
  // yesterday,
  // tomorrow,
  handleDateChange,
  selectedDate,
}) => {
  return (
    <div className="customDatePicker">
      <label>Select date: </label>
      <DatePicker
        selected={selectedDate}
        onChange={(date: Date) => handleDateChange(date)}
        dateFormat="dd.MM.yyyy"
      />
    </div>
  )
}
