import { GoaliesStats, PlayerStats } from "./Stats"
import { BothStats, Columns, GoalieColumn, PlayerColumn } from "./StatsTable"
import config from "../../config"

type StatsTableBodyProps = {
  columns: Columns
  tableData: BothStats[]
  type: "P" | "G"
}

const TableBody: React.FunctionComponent<StatsTableBodyProps> = ({ columns, tableData, type }) => {
  if (type === "P") {
    const playerData = tableData as PlayerStats[]
    const playerColumns = columns as PlayerColumn[]
    return (
      <tbody>
        {playerData.map((data, i) => {
          return (
            <tr key={`player-${data.player_name}-${i}`}>
              {playerColumns.flatMap(({ accessor }) => {
                if (
                  accessor === "season_total_power_play_assists" ||
                  accessor === "season_total_power_play_goals" ||
                  accessor === "season_total_short_handed_assists" ||
                  accessor === "season_total_short_handed_goals"
                ) {
                  return []
                }
                let tData = data[accessor] ? data[accessor] : "——"
                if (accessor === "season_total_power_play_points") {
                  tData = `${data[accessor]} (${data["season_total_power_play_goals"]}/${data["season_total_power_play_assists"]})`
                }
                if (accessor === "season_total_short_handed_points") {
                  tData = `${data[accessor]} (${data["season_total_short_handed_goals"]}/${data["season_total_short_handed_assists"]})`
                }
                if (accessor === "player_nationality") {
                  return (
                    <td id="table-img">
                      <img
                        alt="nat-stat-flag"
                        src={`${config.staticBaseUrl}/country_flags/${tData}.svg`}
                        width="25px"
                      />
                    </td>
                  )
                }
                if (accessor === "player_team_id") {
                  return (
                    <td id="table-img">
                      <img
                        alt="team"
                        src={`${config.staticBaseUrl}/team_logos/${tData}.svg`}
                        width="30px"
                      />
                    </td>
                  )
                }
                return <td key={accessor}>{tData}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    )
  } else if (type === "G") {
    const goalieData = tableData as GoaliesStats[]
    const goalieColumns = columns as GoalieColumn[]
    return (
      <tbody>
        {goalieData.map((data, i) => {
          return (
            <tr key={`goalie-${data.player_name}-${i}`}>
              {goalieColumns.flatMap(({ accessor }) => {
                const tData = data[accessor] ? data[accessor] : "——"
                if (accessor === "player_nationality") {
                  return (
                    <td id="table-img">
                      <img
                        alt="nat-stat-flag"
                        src={`${config.staticBaseUrl}/country_flags/${tData}.svg`}
                        width="25px"
                      />
                    </td>
                  )
                }
                if (accessor === "player_team_id") {
                  return (
                    <td id="table-img">
                      <img
                        alt="team"
                        src={`${config.staticBaseUrl}/team_logos/${tData}.svg`}
                        width="30px"
                      />
                    </td>
                  )
                }
                return <td key={accessor}>{tData}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    )
  }

  return <></>
}

export default TableBody
