import { useEffect, useState } from "react"
import arrowDown from "../../assets/arrowDown.png"

import config from "../../config"
import { MEDIUM_SCREEN } from "../../utils"
import { DetailsBlock } from "./DetailsBlock"
import { useViewport } from "../../hooks/useViewport"
import "./GameBlock.css"

enum TeamType {
  home = "home",
  away = "away",
}

enum PlayerType {
  p = "P",
  g = "G",
}

export type PlayerActions = {
  goals: number | null
  assists: number | null
  player_id: string
  player_name: string
  player_type: string
  player_nationality: string
  player_team_id: number
  goals_allowed: number | null
  save_percentage: number | null
  shots_against: number | null
  saves: number | null
  shutout: number | null
  time_on_ice: number | null
  plus_minus: number | null
  shots: number | null
  hits: number | null
  blocks: number | null
  penalty_minutes: number | null
}

export type GameInterface = {
  away_score: number
  away_team_id: number
  away_team_name: string
  game_date: string
  game_id: number
  home_score: number
  home_team_id: number
  home_team_name: string
  result_period: string
  players: PlayerActions[]
}

export type GameProps = {
  game: GameInterface
  nationality?: string
  expanded: boolean
}

const renderArrow = (open: boolean) => {
  return open ? (
    <img src={arrowDown} alt="more" width="15px" style={{ transform: "rotate(180deg)" }} />
  ) : (
    <img src={arrowDown} alt="more" width="15px" />
  )
}

const filterPlayers = (
  game: GameInterface,
  nationality: string | undefined,
  teamType: TeamType,
  playerType: PlayerType,
  expanded: boolean
) => {
  let players = [] as PlayerActions[]
  // Always show goalies that had TOI. For skaters by default who scored, when expanded is true show all that had TOI
  if (teamType === TeamType.home) {
    const homePlayers = game.players.filter(
      (player) => player.player_team_id === game.home_team_id && player.player_type === playerType
    )

    players = homePlayers.filter((player) =>
      expanded
        ? player.time_on_ice!
        : (playerType === PlayerType.p && (player.goals! > 0 || player.assists! > 0)) ||
          (playerType === PlayerType.g && player.time_on_ice!)
    )
  } else if (teamType === TeamType.away) {
    const awayPlayers = game.players.filter(
      (player) => player.player_team_id === game.away_team_id && player.player_type === playerType
    )

    players = awayPlayers.filter((player) =>
      expanded
        ? player.time_on_ice!
        : (playerType === PlayerType.p && (player.goals! > 0 || player.assists! > 0)) ||
          (playerType === PlayerType.g && player.time_on_ice!)
    )
  }

  return nationality !== "ALL"
    ? players.filter((player) => player.player_nationality === nationality)
    : players
}

export const GameBlock: React.FunctionComponent<GameProps> = ({ game, nationality, expanded }) => {
  const [showDetails, setShowDetails] = useState(false)
  const [noDetails, setNoDetails] = useState(false)
  const homePlayers = filterPlayers(game, nationality, TeamType.home, PlayerType.p, expanded)
  const homeGoalies = filterPlayers(game, nationality, TeamType.home, PlayerType.g, expanded)
  const awayPlayers = filterPlayers(game, nationality, TeamType.away, PlayerType.p, expanded)
  const awayGoalies = filterPlayers(game, nationality, TeamType.away, PlayerType.g, expanded)
  const homeLogoUrl = `${config.staticBaseUrl}/team_logos/${game.home_team_id}.svg`
  const awayLogoUrl = `${config.staticBaseUrl}/team_logos/${game.away_team_id}.svg`

  useEffect(() => {
    if (
      homePlayers.length === 0 &&
      homeGoalies.length === 0 &&
      awayPlayers.length === 0 &&
      awayGoalies.length === 0
    ) {
      setNoDetails(true)
    } else {
      setNoDetails(false)
    }
  }, [awayGoalies.length, awayPlayers.length, homeGoalies.length, homePlayers.length, setNoDetails])

  useEffect(() => {
    if (!noDetails && nationality !== "ALL") {
      setShowDetails(true)
    }
  }, [nationality, noDetails])

  const { width } = useViewport()
  const isSmallScreen = width <= MEDIUM_SCREEN

  const GameBlockHeader = () => {
    return (
      <>
        <div className={isSmallScreen ? "gameBlockHeader_mobile" : "gameBlockHeader_desktop"}>
          <div className="homeBlock">
            <img className="teamLogo" alt="homelogo" src={homeLogoUrl} width="90px" height="90px" />
            <span className="teamName">{game.home_team_name}</span>
          </div>
          {!isSmallScreen && (
            <div className="scoreBlock scoreBlock_desktop">
              {(game.result_period !== "Scheduled" && game.result_period !== "Postponed") ? 
                <>
                  <div>{game.home_score}</div>
                    <div> - </div>
                  <div>{game.away_score}</div>
                </> : <></>
              }
              {game.result_period !== "REGULAR" ?
              <div className="resultPeriod">{game.result_period}</div> :
              <></>
              }
            </div>
          )}
          <div className="awayBlock">
            <span className="teamName">{game.away_team_name}</span>
            <img className="teamLogo" alt="awaylogo" src={awayLogoUrl} width="90px" height="90px" />
          </div>
        </div>
        {isSmallScreen && (
          <div className="scoreBlock scoreBlock_mobile">
            {(game.result_period !== "Scheduled" && game.result_period !== "Postponed") ? 
              <>
                <div>{game.home_score}</div>
                  <div> - </div>
                <div>{game.away_score}</div>
              </> : <></>
            }
            {game.result_period !== "REGULAR" ?
              <div className="resultPeriod">{game.result_period}</div> :
              <></>
              }
          </div>
        )}
      </>
    )
  }

  return (
    <div className="gameBlock" onClick={() => setShowDetails(!showDetails)}>
      <GameBlockHeader />
      <div className="more">{noDetails ? <></> : renderArrow(showDetails)}</div>
      {showDetails ? (
        <div className="scrollTable">
          <DetailsBlock
            {...{ homePlayers, homeGoalies, awayPlayers, awayGoalies, noDetails, expanded, homeLogoUrl, awayLogoUrl }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
