import { useEffect, useState } from "react"
import { fetchGamesByDate, fetchLatestGameDate, fetchNationalities } from "./api/api"
import "./App.css"
import { GameList } from "./components/GameList"
import { Stats } from "./components/stats/Stats"
import { Navigation } from "./components/Navigation"
import { useViewport } from "./hooks/useViewport"
import { SMALL_SCREEN } from "./utils"

const App = () => {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [dateFetched, setDateFetched] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [games, setGames] = useState([])
  const [viewGames, setViewGames] = useState(true)
  const [nationality, setNationality] = useState("ALL")
  const [nationalities, setNationalities] = useState([])
  const [statToggle, setStatToggle] = useState(false)

  const { width } = useViewport()
  const isSmallScreen = width <= SMALL_SCREEN

  const getCookie = (country: string) => {
    const nat = country + "="
    const ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === " ") {
        c = c.substring(1)
      }
      if (c.indexOf(nat) === 0) {
        return c.substring(nat.length, c.length)
      }
    }
    return ""
  }

  const checkCookie = () => {
    const country = getCookie("country")
    if (country !== "") {
      setNationality(country)
    }
  }

  const yesterday = () => {
    const yesterday = new Date(selectedDate)
    yesterday.setDate(yesterday.getDate() - 1)
    return yesterday
  }

  const tomorrow = () => {
    const tomorrow = new Date(selectedDate)
    tomorrow.setDate(tomorrow.getDate() + 1)
    return tomorrow
  }

  const handleDateChange = (date: Date) => {
    setSelectedDate(date)
    setLoaded(false)
  }

  useEffect(() => {
    if (nationalities.length === 0) {
      fetchNationalities().then((res) => {
        setNationalities(res)
      })
    }
  }, [nationalities.length])

  useEffect(() => {
    if (dateFetched === false) {
      fetchLatestGameDate().then((res) => {
        const dateDetails = res[0].date.split("-")
        setSelectedDate(new Date(dateDetails[0], dateDetails[1] - 1, dateDetails[2]))
        setDateFetched(true)
      })
    }
  }, [dateFetched])

  useEffect(() => {
    if (loaded === false && dateFetched === true) {
      fetchGamesByDate(selectedDate).then((res) => {
        setGames(res)
        setLoaded(true)
      })
    }
  }, [selectedDate, loaded, dateFetched])

  const handleStatToggle = () => {
    setStatToggle(!statToggle)
  }

  return (
    // eslint-disable-next-line react/no-unknown-property
    <div className="App" onLoad={checkCookie}>
      <Navigation
        yesterday={yesterday}
        tomorrow={tomorrow}
        handleDateChange={handleDateChange}
        handleStatToggle={handleStatToggle}
        selectedDate={selectedDate}
        nationality={nationality}
        nationalities={nationalities}
        setNationality={setNationality}
        setViewGames={setViewGames}
        statToggle={statToggle}
        viewGames={viewGames}
      />
      <div className={"content" + (statToggle ? "-expanded" : "")}>
        {viewGames && !isSmallScreen ? (
          <div className="expandStats">
            <input
              onChange={() => handleStatToggle()}
              type="checkbox"
              id="expand-stats"
              name="expand-stats"
              checked={statToggle}
            />
            <label htmlFor="expand-stats">Expand stats</label>
          </div>
        ) : (
          <></>
        )}
        {viewGames ? (
          <GameList loaded={loaded} games={games} nationality={nationality} expanded={statToggle} />
        ) : (
          <Stats nationality={nationality} />
        )}
      </div>
    </div>
  )
}

export default App
