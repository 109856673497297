import { useEffect, useState } from "react"
import { fetchSeasonTotalsForGoalies, fetchSeasonTotalsForPlayers } from "../../api/api"
import StatsTable from "./StatsTable"
import "./Stats.css"

export type PlayerStats = {
    player_name: string,
    player_nationality: string,
    player_team_id: number,
    season: string,
    season_total_assists: number,
    season_total_games_played: number,
    season_total_goals: number,
    season_total_points: number,
    average_time_on_ice: number,
    plus_minus: number,
    season_total_power_play_assists: number,
    season_total_power_play_goals: number,
    season_total_power_play_points: number,
    season_total_short_handed_assists: number,
    season_total_short_handed_goals: number,
    season_total_short_handed_points: number,
    season_total_shots: number,
    season_total_hits: number,
    season_total_blocks: number,
    season_total_penalty_minutes: number
}

export type PlayerKeys = keyof PlayerStats

export type GoaliesStats = {
    player_name: string,
    player_team_id: number,
    player_nationality: string,
    season: string,
    season_average_goals_allowed: number,
    season_average_save_percentage: number,
    season_average_shots_against: number,
    season_total_assists: number,
    season_total_games_played: number,
    season_total_goals: number,
    season_total_points: number,
    season_total_shutouts: number,
    season_total_penalty_minutes: number,
    average_even_strength_save_percentage: number,
    average_power_play_save_percentage: number
}

export type GoaliesKeys = keyof GoaliesStats

export type BothKeys = PlayerKeys | GoaliesKeys

type StatsProps = {
  nationality: string
}

export const Stats: React.FunctionComponent<StatsProps> = ({ nationality }) => {
  const [loaded, setLoaded] = useState(false)
  const [playerStats, setPlayerStats] = useState([])
  const [goaliesStats, setGoaliesStats] = useState([])
  const [playerView, setPlayerView] = useState(true)
  const [playerLinkActive, setPlayerLinkActive] = useState(true)

  useEffect(() => {
    fetchSeasonTotalsForPlayers(nationality).then((res) => {
      setPlayerStats(res)
      setLoaded(true)
    })
    fetchSeasonTotalsForGoalies(nationality).then((res) => {
      setGoaliesStats(res)
      setLoaded(true)
    })
  }, [nationality])

  const handleSorting = (sortField: BothKeys, sortOrder: string, type: "P" | "G") => {
    if (sortField && type === "P") {
      const field = sortField as PlayerKeys
      const sorted = [...playerStats].sort((a, b) => {
        if (a[field] === null) return 1
        if (b[field] === null) return -1
        if (a[field] === null && b[field] === null) return 0
        return (a[field] - b[field]) * (sortOrder === "desc" ? 1 : -1)
      })
      setPlayerStats(sorted)
    }
    if (sortField && type === "G") {
      const field = sortField as GoaliesKeys
      const sorted = [...goaliesStats].sort((a, b) => {
        if (a[field] === null) return 1
        if (b[field] === null) return -1
        if (a[field] === null && b[field] === null) return 0
        return (a[field] - b[field]) * (sortOrder === "desc" ? 1 : -1)
      })
      setGoaliesStats(sorted)
    }
  }

  return (
    <div className="statsContent">
      <div className="filterView">
        <button
          className={playerLinkActive ? "active" : ""}
          onClick={() => {
            setPlayerLinkActive(true)
            setPlayerView(true)
          }}
        >
          Players
        </button>
        <button
          className={playerLinkActive ? "" : "active"}
          onClick={() => {
            setPlayerLinkActive(false)
            setPlayerView(false)
          }}
        >
          Goalies
        </button>
      </div>
      {loaded ? (
        <>
          {playerView ? (
            <StatsTable data={playerStats} type={"P"} handleSorting={handleSorting} />
          ) : (
            <StatsTable data={goaliesStats} type={"G"} handleSorting={handleSorting} />
          )}
        </>
      ) : (
        <p>Loading</p>
      )}
    </div>
  )
}
