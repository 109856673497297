import { GoaliesKeys, PlayerKeys, PlayerStats, GoaliesStats, BothKeys } from "./Stats"
import TableBody from "./TableBody"
import TableHead from "./TableHead"

export type PlayerColumn = {
  label: string
  accessor: PlayerKeys
  sortable: boolean
}

export type GoalieColumn = {
  label: string
  accessor: GoaliesKeys
  sortable: boolean
}

export type BothStats = PlayerStats | GoaliesStats

type StatsTableProps = {
  data: BothStats[]
  type: "P" | "G"
  handleSorting: (sortField: BothKeys, sortOrder: string, type: "P" | "G") => void
}

export type Columns = PlayerColumn[] | GoalieColumn[]

const StatsTable: React.FunctionComponent<StatsTableProps> = ({ data, type, handleSorting }) => {

    const playerColumns = [
        { label: "NAT", accessor: "player_nationality", sortable: false },
        { label: "Team", accessor: "player_team_id", sortable: false },
        { label: "Name", accessor: "player_name", sortable: false },
        { label: "Games played", accessor: "season_total_games_played", sortable: true },
        { label: "Total points", accessor: "season_total_points", sortable: true },
        { label: "Goals", accessor: "season_total_goals", sortable: true },
        { label: "Assists", accessor: "season_total_assists", sortable: true },
        { label: "Average time on ice", accessor: "average_time_on_ice", sortable: true },
        { label: "Plus minus", accessor: "plus_minus", sortable: true },
        { label: "Power play assists", accessor: "season_total_power_play_assists", sortable: true },
        { label: "Power play goals", accessor: "season_total_power_play_goals", sortable: true },
        { label: "PP total points", accessor: "season_total_power_play_points", sortable: true },
        { label: "Short handed assists", accessor: "season_total_short_handed_assists", sortable: true },
        { label: "Short handed goals", accessor: "season_total_short_handed_goals", sortable: true },
        { label: "SH total points", accessor: "season_total_short_handed_points", sortable: true },
        { label: "Shots", accessor: "season_total_shots", sortable: true },
        { label: "Hits", accessor: "season_total_hits", sortable: true },
        { label: "Blocks", accessor: "season_total_blocks", sortable: true },
        { label: "Penalty minutes", accessor: "season_total_penalty_minutes", sortable: true },
    ] as PlayerColumn[];

    const goaliesColumns = [
        { label: "NAT", accessor: "player_nationality", sortable: false },
        { label: "Team", accessor: "player_team_id", sortable: false },
        { label: "Name", accessor: "player_name", sortable: false },
        { label: "Games played", accessor: "season_total_games_played", sortable: true },
        { label: "Save percentage", accessor: "season_average_save_percentage", sortable: true },
        { label: "GAA", accessor: "season_average_goals_allowed", sortable: true },
        { label: "Shutouts", accessor: "season_total_shutouts", sortable: true },
        { label: "Shots against", accessor: "season_total_shots_against", sortable: true },
        { label: "Even Save%", accessor: "average_even_strength_save_percentage", sortable: true },
        { label: "PP Save%", accessor: "average_power_play_save_percentage", sortable: true },
        { label: "Goals", accessor: "season_total_goals", sortable: true },
        { label: "Assists", accessor: "season_total_assists", sortable: true },
        { label: "Penalty minutes", accessor: "season_total_penalty_minutes", sortable: true },
    ] as GoalieColumn[];

  return (
    <div className="scrollTable">
      {type === "P" ? (
        <table className="statsTable">
          <TableHead columns={playerColumns} handleSorting={handleSorting} type={type} />
          <TableBody columns={playerColumns} tableData={data} type={type} />
        </table>
      ) : (
        <table className="statsTable">
          <TableHead columns={goaliesColumns} handleSorting={handleSorting} type={type} />
          <TableBody columns={goaliesColumns} tableData={data} type={type} />
        </table>
      )}
    </div>
  )
}

export default StatsTable
