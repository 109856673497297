import config from "../config"

export const fetchNationalities = async () => {
  const response = await fetch(`${config.apiBaseUrl}/v1/player_nationalities`)
  return response.json()
}

export const fetchLatestGameDate = async () => {
  const response = await fetch(`${config.apiBaseUrl}/v1/latest_date`)
  return response.json()
}

export const fetchGamesByDate = async (date: Date) => {
  const [month, day, year] = [date.getMonth() + 1, date.getDate(), date.getFullYear()]
  const gameDate = `${year}-${month}-${day}`
  const response = await fetch(`${config.apiBaseUrl}/v3/games_with_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      start_date: gameDate,
      end_date: gameDate,
    }),
  })
  return response.json()
}

export const fetchSeasonTotalsForPlayers = async (nationality?: string) => {
  const nat = nationality ? nationality : "all"

  // These are placeholders, to be UI input
  const season = "2022/2023"
  const seasonType = "R"

  const response = await fetch(`${config.apiBaseUrl}/v3/players_season_totals`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      nationality: nat,
      season: season,
      season_type: seasonType,
    }),
  })
  return response.json()
}

export const fetchSeasonTotalsForGoalies = async (nationality?: string) => {
  const nat = nationality ? nationality : "all"

  // These are placeholders, to be UI input
  const season = "2022/2023"
  const seasonType = "R"

  const response = await fetch(`${config.apiBaseUrl}/v3/goalies_season_totals`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      nationality: nat,
      season: season,
      season_type: seasonType,
    }),
  })
  return response.json()
}
