import React from "react"
import "react-datepicker/dist/react-datepicker.css"
import config from "../config"
import "./DatePicker.css"
import { Nationality } from "./NationalitySelect"

type DropdownProps = {
  options: Nationality[]
  handleChange: (nationality: string) => void
  handleClick: () => void
  selectedOption: string
  menuOpen: boolean
  closeMenu: () => void
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  handleChange,
  handleClick,
  selectedOption,
  menuOpen,
  closeMenu,
}) => {
  const result = options.map((option, i) => {
    return (
      <div
        key={i}
        className="dd-option"
        onClick={() => {
          handleChange(option.player_nationality)
        }}
      >
        <img
          alt="natflag"
          src={`${config.staticBaseUrl}/country_flags/${option.player_nationality}.svg`}
          width="18px"
          height="10px"
        />
        <span>{option.player_nationality}</span>
      </div>
    )
  })

  return (
    <div className="dropdownWrapper">
      <label>Select nationality: </label>
      <div className="dropdown" tabIndex={0} onBlur={closeMenu}>
        <div className="dd-selected" onClick={handleClick}>
          <img
            alt="natflag"
            src={`${config.staticBaseUrl}/country_flags/${selectedOption}.svg`}
            width="18px"
            height="10px"
          />
          <span>{selectedOption}</span>
        </div>
        {menuOpen && (
          <div className="dd-optionsHolder">
            <div className="dd-option" onClick={() => handleChange("ALL")}>
              <img
                alt="natflag"
                src={`${config.staticBaseUrl}/country_flags/ALL.svg`}
                width="18px"
                height="10px"
              />
              <span>ALL</span>
            </div>
            {result}
          </div>
        )}
      </div>
    </div>
  )
}
