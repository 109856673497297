import { useState } from "react"
import { Dropdown } from "./Dropdown"
import "./Dropdown.css"

export type Nationality = {
  player_nationality: string
}

type NationalityProps = {
  nationality: string
  nationalities: Nationality[]
  setNationality: (nationality: string) => void
}

export const NationalitySelector: React.FunctionComponent<NationalityProps> = ({
  nationality,
  nationalities,
  setNationality,
}) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const setCookie = (country: string, exp: number) => {
    const d = new Date()
    d.setTime(d.getTime() + exp * 24 * 60 * 60 * 1000)
    const expires = "expires=" + d.toUTCString()
    document.cookie = "country=" + country + ";" + expires + ";path=/"
  }

  const handleClick = () => {
    setMenuOpen(true)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  const handleChange = (nationality: string) => {
    setNationality(nationality)
    setMenuOpen(false)
    setCookie(nationality, 60)
  }

  return (
    <Dropdown
      options={nationalities}
      handleChange={handleChange}
      handleClick={handleClick}
      selectedOption={nationality}
      menuOpen={menuOpen}
      closeMenu={closeMenu}
    />
  )
}
