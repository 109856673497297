import React from "react"
import "./Navigation.css"

import { Nationality, NationalitySelector } from "./NationalitySelect"
import { CustomDatePicker } from "./CustomDatePicker"
import { useViewport } from "../hooks/useViewport"
import { MEDIUM_PLUS, MEDIUM_SCREEN } from "../utils"

type NavigationProps = {
  yesterday: () => Date
  tomorrow: () => Date
  handleDateChange: (date: Date) => void
  handleStatToggle: () => void
  selectedDate: Date
  nationality: string
  nationalities: Nationality[]
  setNationality: (value: string) => void
  setViewGames: (value: boolean) => void
  statToggle: boolean
  viewGames: boolean
}

export const Navigation: React.FC<NavigationProps> = ({
  // yesterday,
  // tomorrow,
  handleDateChange,
  selectedDate,
  nationality,
  nationalities,
  setNationality,
  setViewGames,
  viewGames,
}) => {
  const { width } = useViewport()
  const isSemiSmallScreen = width <= MEDIUM_SCREEN
  const isMediumScreen = width <= MEDIUM_PLUS && width > MEDIUM_SCREEN

  const gameStatsClassName = viewGames ? "active_link" : ""
  const playerStatsClassName = !viewGames ? "active_link" : ""

  const MobileNavi = () => (
    <>
      <div className="twoInRow_mobile">
        <CustomDatePicker
          // yesterday={yesterday}
          // tomorrow={tomorrow}
          handleDateChange={handleDateChange}
          selectedDate={selectedDate}
        />

        <NationalitySelector
          nationality={nationality}
          nationalities={nationalities}
          setNationality={setNationality}
        />
      </div>
      <div className="navigationTabs">
        <a className={gameStatsClassName} onClick={() => setViewGames(true)}>
          Game stats
        </a>{" "}
        | {"   "}
        <a className={playerStatsClassName} onClick={() => setViewGames(false)}>
          Player stats
        </a>
      </div>
    </>
  )

  const DesktopNavi = () => (
    <div className="threeInRow_desktop">
      <CustomDatePicker
        // yesterday={yesterday}
        // tomorrow={tomorrow}
        handleDateChange={handleDateChange}
        selectedDate={selectedDate}
      />
      <NationalitySelector
        nationality={nationality}
        nationalities={nationalities}
        setNationality={setNationality}
      />
      <div className="navigationTabs">
        <a className={gameStatsClassName} onClick={() => setViewGames(true)}>
          Game stats
        </a>{" "}
        | {"   "}
        <a className={playerStatsClassName} onClick={() => setViewGames(false)}>
          Player stats
        </a>
      </div>
    </div>
  )

  const WideScreenNavi = () => (
    <>
      <CustomDatePicker
        // yesterday={yesterday}
        // tomorrow={tomorrow}
        handleDateChange={handleDateChange}
        selectedDate={selectedDate}
      />
      <NationalitySelector
        nationality={nationality}
        nationalities={nationalities}
        setNationality={setNationality}
      />
      <div className="navigationTabs">
        <a className={gameStatsClassName} onClick={() => setViewGames(true)}>
          Game stats
        </a>{" "}
        | {"   "}
        <a className={playerStatsClassName} onClick={() => setViewGames(false)}>
          Player stats
        </a>
      </div>
    </>
  )

  return (
    <div className="nav">
      <div className="innerNav">
        <div className="logo">
          <span className="main">Hockey Night</span>
          <span className="second">Live</span>
        </div>

        <>
          {isSemiSmallScreen && <MobileNavi />}
          {isMediumScreen && <DesktopNavi />}
          {!isSemiSmallScreen && !isMediumScreen && <WideScreenNavi />}
        </>
      </div>
    </div>
  )
}
